import { Category } from 'src/common/models/category';
import { getSlug } from 'src/common/services/slug';
import { buildPathname, buildRoute, translateSegments } from 'src/lib/router.utils';
import { concat } from 'ramda';
import { setParams } from 'src/common/utils/routing';

const searchSlugParam = ':categorySlug';

const sourceSearchSegment = 'search';

const segmentsTranslations = {
  [sourceSearchSegment]: 'szkolenia',
};

const segmentTranslator = (segments: string[]) => translateSegments(segments, segmentsTranslations);

const sourceSearchRouteSegments = [sourceSearchSegment];
const sourceSearchSlugRouteSegments = concat(sourceSearchRouteSegments, [searchSlugParam]);

const translatedSearchRouteSegments = segmentTranslator(sourceSearchRouteSegments);
const translatedSearchSlugRouteSegments = segmentTranslator(sourceSearchSlugRouteSegments);

const SourceSearchRoute = buildRoute(sourceSearchRouteSegments);
const SourceSearchSlugRoute = buildRoute(sourceSearchSlugRouteSegments);

const TranslatedSearchRoute = buildRoute(translatedSearchRouteSegments);
const TranslatedSearchSlugRoute = buildRoute(translatedSearchSlugRouteSegments);

export const SearchRoute = TranslatedSearchRoute;
export const SearchSlugRoute = TranslatedSearchSlugRoute;

export const searchPathnameTranslations = {
  [buildPathname(sourceSearchRouteSegments)]: buildPathname(translatedSearchRouteSegments),
  [buildPathname(sourceSearchSlugRouteSegments)]: buildPathname(translatedSearchSlugRouteSegments),
};

export const searchTranslatedRoutes = [
  { source: SourceSearchRoute, translated: TranslatedSearchRoute },
  { source: SourceSearchSlugRoute, translated: TranslatedSearchSlugRoute },
];

export const setSearchRouteParams = ({
  categorySlug,
  categoryId,
}: {
  categorySlug: string;
  categoryId: number;
}): string =>
  setParams(':categorySlug', ':categoryId')(SearchSlugRoute)(categorySlug, String(categoryId));

export const getLinkToSearchCategory = (category: Category) =>
  setSearchRouteParams({
    categoryId: category.id,
    categorySlug: getSlug(category),
  });
