import React, { memo, useCallback } from 'react';
import ChevronIcon from 'src/assets/icons/ChevronIcon';
import { CleanButton } from 'src/common/components/Button';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { rem } from 'polished';
import { media } from 'src/config/breakpoints';
import { layers } from 'src/config/layers';

const Button = styled(CleanButton)<{ padding: number }>`
  background: ${colors.darkGrey};
  border-radius: 100%;
  bottom: ${({ padding }) => rem(padding + 10)};
  height: 2.5rem;
  position: fixed;
  right: 0.75rem;
  width: 2.5rem;
  z-index: ${layers.scrollToTop};

  ${media.w.min.px768(css`
    display: none;
  `)};
`;

export interface ScrollToTopProps {
  widgetPadding?: number;
}

export const ScrollToTop = memo<ScrollToTopProps>(({ widgetPadding = 0 }) => {
  const handleClick = useCallback(
    () =>
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      }),
    [],
  );

  return (
    <Button onClick={handleClick} padding={widgetPadding}>
      <ChevronIcon direction="up" size={18} />
    </Button>
  );
});
ScrollToTop.displayName = 'ScrollToTop';
