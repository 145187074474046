import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'src/common/components/Button';
import { colors, shadows } from 'src/config/colors';
import { media } from 'src/config/breakpoints';
import { typography } from 'src/config/typography';
import { useDispatch, useSelector } from 'react-redux';
import { cookiesAcceptedSelector } from 'src/state/cookies/cookiesSelectors';
import { cookiesActions } from 'src/state/cookies/cookiesActions';
import { isSome } from 'fp-ts/lib/Option';
import { CookiePolicyRoute } from 'src/public/publicRoutes';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';

const Container = styled.div`
  position: fixed;
  bottom: 0.625rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
  display: flex;
  flex-wrap: wrap;
  background-color: ${colors.white};
  width: calc(100% - 1.5rem);
  border-radius: 0.5rem;
  box-shadow: ${shadows.drop1};
  padding: 0.625rem;
  max-width: 36.25rem;

  ${media.w.min.px768(css`
    width: calc(100% - 2.25rem);
    max-width: 73.8125rem;
    padding: 1.0625rem 0.875rem;
  `)}
  ${media.w.min.px1200(css`
    padding: 0.875rem 2rem;
  `)};
`;

const Title = styled.div`
  width: 11.125rem;
  color: ${colors.blue};
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.625rem;
  font-weight: bold;
  line-height: 0.875rem;
  margin-right: 1.5rem;
  ${media.w.min.px992(css`
    font-size: 0.75rem;
    line-height: 1rem;
  `)}
`;

const Description = styled.div`
  color: ${colors.blue};
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.625rem;
  line-height: 0.875rem;
  max-width: 42.75rem;
  ${media.w.min.px992(css`
    font-size: 0.75rem;
    line-height: 1rem;
    max-width: 42.75rem;
  `)}
`;

const StyledLink = styled(GatsbyLink)`
  color: ${colors.blue};
`;

const PolicyDisclaimer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 28.25rem;
  flex-wrap: wrap;
  color: ${colors.blue};
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.625rem;
  line-height: 0.875rem;
  ${media.w.min.px768(css`
    margin-right: 3.3125rem;
  `)}
  ${media.w.min.px768(css`
    margin-right: 1.5rem;
  `)}
`;

const AcceptPolicyButton = styled(Button)`
  width: 100%;
  margin-top: 0.625rem;
  ${media.w.min.px768(css`
    margin-top: 0;
    width: auto;
  `)}
`;

export const CookiePolicyBar = () => {
  const cookiesAccepted = useSelector(cookiesAcceptedSelector);
  const dispatch = useDispatch();

  const show = !isSome(cookiesAccepted);

  if (!show) {
    return <></>;
  }

  return (
    <Container>
      <PolicyDisclaimer>
        <Title>Cenimy Twoją prywatność</Title>
        <Description>
          Używamy plików cookie, aby dopasować szkolenia do Twoich potrzeb i mierzyć ich
          skuteczność. Wszystko po to by, zapewniać lepszą jakość naszych usług. W dowolnej chwili
          możesz zmienić preferencje{' '}
          <StyledLink to={CookiePolicyRoute}>polityki ciasteczek</StyledLink>
        </Description>
      </PolicyDisclaimer>
      <AcceptPolicyButton onClick={() => dispatch(cookiesActions.accept())} kind="secondary">
        Akceptuję wszystkie cookies
      </AcceptPolicyButton>
    </Container>
  );
};
