import { ElearningUserEnrollment } from 'src/common/models/elearning';
import {
  getUserElearningLessonPageRoute,
  userElearningIdParam,
  UserElearningItemRoute,
} from 'src/user/routes';
import { getSlug, Sluggable } from 'src/common/services/slug';
import { buildPathname, buildRoute, translateSegments } from 'src/lib/router.utils';
import { setParams } from 'src/common/utils/routing';

const elearningIdParam = ':elearningId';
const elearningSlugParam = ':slug';

const sourceItemSegment = 'e-learning';

const segmentsTranslations = {
  [sourceItemSegment]: 'e-learning',
};

const segmentTranslator = (segments: string[]) => translateSegments(segments, segmentsTranslations);

const sourceItemRouteSegments = ['e-learning', elearningSlugParam, elearningIdParam];

const translatedItemRouteSegments = segmentTranslator(sourceItemRouteSegments);

const SourceElearningItemRoute = buildRoute(sourceItemRouteSegments);

const TranslatedElearningItemRoute = buildRoute(translatedItemRouteSegments);

export const ElearningItemRoute = TranslatedElearningItemRoute;

export const elearningPathnameTranslations = {
  [buildPathname(sourceItemRouteSegments)]: buildPathname(translatedItemRouteSegments),
};

export const elearningTranslatedRoutes = [
  { source: SourceElearningItemRoute, translated: TranslatedElearningItemRoute },
];

export const getElearningRoute = (
  elearningUserEnrollment: ElearningUserEnrollment | null,
  elearning: { id: number } & Sluggable,
) => {
  if (!elearningUserEnrollment || !elearningUserEnrollment.isEnrolled) {
    return getElearningSlugRoute(elearning);
  }

  if (
    !elearningUserEnrollment.farthestLessonId ||
    !elearningUserEnrollment.farthestPointInSeconds
  ) {
    return setParams(userElearningIdParam)(UserElearningItemRoute)(String(elearning.id));
  }

  return `${getUserElearningLessonPageRoute(
    elearning.id,
    elearningUserEnrollment.farthestLessonId,
  )}?t=${elearningUserEnrollment.farthestPointInSeconds}`;
};

export const getElearningSlugRoute = (elearning: { id: number } & Sluggable) => {
  return setParams(elearningIdParam, elearningSlugParam)(ElearningItemRoute)(
    String(elearning.id),
    getSlug(elearning),
  );
};
