import { isNotNilEmpty } from 'src/lib/isNilEmpty';
import { buildPathname, buildRoute, translateSegments } from 'src/lib/router.utils';
import { concat } from 'ramda';
import { setParams } from 'src/common/utils/routing';

export const userCourseIdParam = ':courseId';
export const userEventIdParam = ':eventId';

export const userElearningIdParam = ':elearningId';
export const userElearningLessonIdParam = ':lessonId';

const sourceCertificatesSegment = 'certificates';
const sourceCongratulationsSegment = 'congratulations';
const sourceCoursesSegment = 'courses';
const sourceCourseBundlesSegment = 'course-bundles';
const sourceElearningSegment = 'e-learning';
const sourceLessonSegment = 'lesson';
const sourceMaterialsSegment = 'materials';
const sourceProfileSegment = 'profile';
const sourceRootSegment = 'user';
const sourceSurveySegment = 'survey';
const sourceThankYouSegment = 'thank-you';

const segmentsTranslations = {
  [sourceCertificatesSegment]: 'certyfikaty',
  [sourceCongratulationsSegment]: 'gratulacje',
  [sourceCoursesSegment]: 'moje-szkolenia',
  [sourceCourseBundlesSegment]: 'kursy',
  [sourceElearningSegment]: 'e-learning',
  [sourceLessonSegment]: 'lekcja',
  [sourceMaterialsSegment]: 'materialy-szkoleniowe',
  [sourceProfileSegment]: 'moje-dane',
  [sourceRootSegment]: 'moje-konto',
  [sourceSurveySegment]: 'ankieta',
  [sourceThankYouSegment]: 'podziekowanie',
};

const segmentTranslator = (segments: string[]) => translateSegments(segments, segmentsTranslations);

const sourceUserRouteSegments = [sourceRootSegment];
const sourceCertificatesRouteSegments = concat(sourceUserRouteSegments, [
  sourceCertificatesSegment,
]);
const sourceCoursesRouteSegments = concat(sourceUserRouteSegments, [sourceCoursesSegment]);
const sourceCourseRecordingRouteSegments = concat(sourceCoursesRouteSegments, [
  userCourseIdParam,
  userEventIdParam,
]);
const sourceCourseBundlesRouteSegments = concat(sourceUserRouteSegments, [
  sourceCourseBundlesSegment,
]);
const sourceElearningItemRouteSegments = concat(sourceUserRouteSegments, [
  sourceElearningSegment,
  userElearningIdParam,
]);
const sourceElearningCongratulationsRouteSegments = concat(sourceElearningItemRouteSegments, [
  sourceCongratulationsSegment,
]);
const sourceElearningLessonRouteSegments = concat(sourceElearningItemRouteSegments, [
  sourceLessonSegment,
  userElearningLessonIdParam,
]);
const sourceElearningSurveyRouteSegments = concat(sourceElearningItemRouteSegments, [
  sourceSurveySegment,
]);
const sourceElearningSurveyThankYouRouteSegments = concat(sourceElearningSurveyRouteSegments, [
  sourceThankYouSegment,
]);
const sourceMaterialsRouteSegments = concat(sourceUserRouteSegments, [sourceMaterialsSegment]);
const sourceProfileRouteSegments = concat(sourceUserRouteSegments, [sourceProfileSegment]);

const translatedUserRouteSegments = segmentTranslator(sourceUserRouteSegments);
const translatedCertificatesRouteSegments = segmentTranslator(sourceCertificatesRouteSegments);
const translatedCoursesRouteSegments = segmentTranslator(sourceCoursesRouteSegments);
const translatedCourseRecordingRouteSegments = segmentTranslator(
  sourceCourseRecordingRouteSegments,
);
const translatedCourseBundlesRouteSegments = segmentTranslator(sourceCourseBundlesRouteSegments);
const translatedElearningItemRouteSegments = segmentTranslator(sourceElearningItemRouteSegments);
const translatedElearningCongratulationsRouteSegments = segmentTranslator(
  sourceElearningCongratulationsRouteSegments,
);
const translatedElearningLessonRouteSegments = segmentTranslator(
  sourceElearningLessonRouteSegments,
);
const translatedElearningSurveyRouteSegments = segmentTranslator(
  sourceElearningSurveyRouteSegments,
);
const translatedElearningSurveyThankYouRouteSegments = segmentTranslator(
  sourceElearningSurveyThankYouRouteSegments,
);
const translatedMaterialsRouteSegments = segmentTranslator(sourceMaterialsRouteSegments);
const translatedProfileRouteSegments = segmentTranslator(sourceProfileRouteSegments);

export const SourceUserRoute = buildRoute(sourceUserRouteSegments);
export const SourceUserCertificatesRoute = buildRoute(sourceCertificatesRouteSegments);
export const SourceUserCoursesRoute = buildRoute(sourceCoursesRouteSegments);
export const SourceUserCourseRecordingRoute = buildRoute(sourceCourseRecordingRouteSegments);
export const SourceUserCourseBundlesRoute = buildRoute(sourceCourseBundlesRouteSegments);
export const SourceUserElearningItemRoute = buildRoute(sourceElearningItemRouteSegments);
export const SourceUserElearningCongratulationsRoute = buildRoute(
  sourceElearningCongratulationsRouteSegments,
);
export const SourceUserElearningLessonRoute = buildRoute(sourceElearningLessonRouteSegments);
export const SourceUserElearningSurveyRoute = buildRoute(sourceElearningSurveyRouteSegments);
export const SourceUserElearningSurveyThankYouRoute = buildRoute(
  sourceElearningSurveyThankYouRouteSegments,
);
export const SourceUserMaterialsRoute = buildRoute(sourceMaterialsRouteSegments);
export const SourceUserProfileRoute = buildRoute(sourceProfileRouteSegments);

export const TranslatedUserRoute = buildRoute(translatedUserRouteSegments);
export const TranslatedUserCertificatesRoute = buildRoute(translatedCertificatesRouteSegments);
export const TranslatedUserCoursesRoute = buildRoute(translatedCoursesRouteSegments);
export const TranslatedUserCourseRecordingRoute = buildRoute(
  translatedCourseRecordingRouteSegments,
);
export const TranslatedUserCourseBundlesRoute = buildRoute(translatedCourseBundlesRouteSegments);
export const TranslatedUserElearningItemRoute = buildRoute(translatedElearningItemRouteSegments);
export const TranslatedUserElearningCongratulationsRoute = buildRoute(
  translatedElearningCongratulationsRouteSegments,
);
export const TranslatedUserElearningLessonRoute = buildRoute(
  translatedElearningLessonRouteSegments,
);
export const TranslatedUserElearningSurveyRoute = buildRoute(
  translatedElearningSurveyRouteSegments,
);
export const TranslatedUserElearningSurveyThankYouRoute = buildRoute(
  translatedElearningSurveyThankYouRouteSegments,
);
export const TranslatedUserMaterialsRoute = buildRoute(translatedMaterialsRouteSegments);
export const TranslatedUserProfileRoute = buildRoute(translatedProfileRouteSegments);

export const UserRoute = TranslatedUserRoute;
export const UserCertificatesRoute = TranslatedUserCertificatesRoute;
export const UserCoursesRoute = TranslatedUserCoursesRoute;
export const UserCourseBundlesRoute = TranslatedUserCourseBundlesRoute;
export const UserCourseRecordingRoute = TranslatedUserCourseRecordingRoute;
export const UserElearningItemRoute = TranslatedUserElearningItemRoute;
export const UserElearningCongratulationsRoute = TranslatedUserElearningCongratulationsRoute;
export const UserElearningLessonRoute = TranslatedUserElearningLessonRoute;
export const UserElearningSurveyRoute = TranslatedUserElearningSurveyRoute;
export const UserElearningSurveyThankYouRoute = TranslatedUserElearningSurveyThankYouRoute;
export const UserMaterialsRoute = TranslatedUserMaterialsRoute;
export const UserProfileRoute = TranslatedUserProfileRoute;

// for CSR only
export const notFoundRoute = '/404';

export const userPathnameTranslations = {
  [buildPathname(sourceUserRouteSegments)]: buildPathname(translatedUserRouteSegments),
  [buildPathname(sourceCertificatesRouteSegments)]: buildPathname(
    translatedCertificatesRouteSegments,
  ),
  [buildPathname(sourceCoursesRouteSegments)]: buildPathname(translatedCoursesRouteSegments),
  [buildPathname(sourceCourseRecordingRouteSegments)]: buildPathname(
    translatedCourseRecordingRouteSegments,
  ),
  [buildPathname(sourceCourseBundlesRouteSegments)]: buildPathname(
    translatedCourseBundlesRouteSegments,
  ),
  [buildPathname(sourceElearningItemRouteSegments)]: buildPathname(
    translatedElearningItemRouteSegments,
  ),
  [buildPathname(sourceElearningCongratulationsRouteSegments)]: buildPathname(
    translatedElearningCongratulationsRouteSegments,
  ),
  [buildPathname(sourceElearningLessonRouteSegments)]: buildPathname(
    translatedElearningLessonRouteSegments,
  ),
  [buildPathname(sourceElearningSurveyRouteSegments)]: buildPathname(
    translatedElearningSurveyRouteSegments,
  ),
  [buildPathname(sourceElearningSurveyThankYouRouteSegments)]: buildPathname(
    translatedElearningSurveyThankYouRouteSegments,
  ),
  [buildPathname(sourceMaterialsRouteSegments)]: buildPathname(translatedMaterialsRouteSegments),
  [buildPathname(sourceProfileRouteSegments)]: buildPathname(translatedProfileRouteSegments),
};

export const userTranslatedRoutes = [
  { source: SourceUserRoute, translated: TranslatedUserRoute },
  { source: SourceUserCertificatesRoute, translated: TranslatedUserCertificatesRoute },
  { source: SourceUserCoursesRoute, translated: TranslatedUserCoursesRoute },
  { source: SourceUserCourseRecordingRoute, translated: TranslatedUserCourseRecordingRoute },
  { source: SourceUserCourseBundlesRoute, translated: TranslatedUserCourseBundlesRoute },
  { source: SourceUserElearningItemRoute, translated: TranslatedUserElearningItemRoute },
  {
    source: SourceUserElearningCongratulationsRoute,
    translated: TranslatedUserElearningCongratulationsRoute,
  },
  { source: SourceUserElearningLessonRoute, translated: TranslatedUserElearningLessonRoute },
  { source: SourceUserElearningSurveyRoute, translated: TranslatedUserElearningSurveyRoute },
  {
    source: SourceUserElearningSurveyThankYouRoute,
    translated: TranslatedUserElearningSurveyThankYouRoute,
  },
  { source: SourceUserMaterialsRoute, translated: TranslatedUserMaterialsRoute },
  { source: SourceUserProfileRoute, translated: TranslatedUserProfileRoute },
];

export interface UserElearningRouteProps {
  elearningId: string;
}

export interface UserElearningLessonIdRouteProps extends UserElearningRouteProps {
  lessonId?: string;
}

export const getUserCourseRecordingRoute = setParams(
  ':courseId',
  ':eventId',
)(UserCourseRecordingRoute);

export const getUserElearningPageRoute = setParams(userElearningIdParam)(UserElearningItemRoute);

export const getUserElearningSurveyPageRoute =
  setParams(userElearningIdParam)(UserElearningSurveyRoute);

export const getUserElearningSurveyThankYouPageRoute = setParams(userElearningIdParam)(
  UserElearningSurveyThankYouRoute,
);

export const getUserElearningSurveyCongratulationsPageRoute = setParams(userElearningIdParam)(
  UserElearningCongratulationsRoute,
);

export const getUserElearningLessonPageRoute = (
  elearningId: number,
  lessonId: number,
  t?: number,
) =>
  `${setParams(userElearningIdParam, userElearningLessonIdParam)(UserElearningLessonRoute)(
    String(elearningId),
    String(lessonId),
  )}${isNotNilEmpty(t) ? `?t=${t}` : ''}`;
