import { useUserQuery } from 'src/common/hooks/useUserQuery';
import { usePartnerRefCode } from 'src/common/hooks/usePartnerRefCode';
import { usePartnerQuery } from 'src/common/hooks/usePartnerQuery';
import { useMemo } from 'react';

export const useAvailablePartner = () => {
  const { data: user, isLoading: isUserLoading } = useUserQuery();

  const partnerCode = usePartnerRefCode();
  const { data: partner, isLoading: isPartnerLoading } = usePartnerQuery(partnerCode);

  return useMemo(() => {
    if (isUserLoading || isPartnerLoading) {
      return {
        data: null,
        isLoading: true,
      };
    }

    return {
      data: user ? user?.partner : partner,
      isLoading: false,
    };
  }, [isUserLoading, user, isPartnerLoading, partner]);
};
