import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { CookiesState } from 'src/state/cookies/cookiesReducer';

export const cookiesStateSelector = (state: RootState) => state.cookies;

export const cookiesAcceptedSelector = createSelector(
  cookiesStateSelector,
  (state: CookiesState) => state.isAccepted,
);
